import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {

  @Input() time = '';

  @Input()
  @HostBinding('attr.color')
  color: 'regular' | 'warning' | 'danger' = 'regular';

  constructor() {
  }

  ngOnInit() {
  }

  get minutes() {
    return this.time?.split(':')[0] || '';
  }

  get seconds() {
    return this.time?.split(':')[1] || '';
  }
}
