import {Component, HostBinding, Input, OnInit} from '@angular/core';

export type CircleSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
export type CircleColor = 'light' | 'dark';
export type CircleDirection = 'top' | 'bottom';
export type CircleSpeed = 1 | 2 | 3 | 4 | 5;

@Component({
  selector: 'app-background-circle',
  templateUrl: './background-circle.component.html',
  styleUrls: ['./background-circle.component.scss']
})
export class BackgroundCircleComponent implements OnInit {

  @Input()
  @HostBinding('attr.size')
  size: CircleSize;

  @Input()
  @HostBinding('attr.color')
  color: CircleColor;

  @Input()
  @HostBinding('attr.direction')
  direction: CircleDirection;

  @Input()
  @HostBinding('attr.speed')
  speed: CircleSpeed;

  @Input()
  @HostBinding('style.right')
  right: string;
  //
  // @Input()
  // @HostBinding('style.top')
  // top: string;

  constructor() {
  }

  ngOnInit() {
  }

}
