import {booleanAttribute, Directive, ElementRef, inject, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements OnChanges {
  private element = inject(ElementRef<HTMLElement>);

  @Input({alias: 'autofocus', transform: booleanAttribute})
  focus: boolean;

  ngOnChanges() {
    if (this.focus) {
      setTimeout(() => this.element.nativeElement.focus(), 5);
    }
  }
}
