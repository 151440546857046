import {Component, HostBinding, Input} from '@angular/core';
import {Avatar, Resource} from '../../../api.graphql.g';

export interface AvatarInput extends Partial<Avatar> {
  image: Resource;
}

export type AvatarSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  @Input()
  @HostBinding('attr.size')
  size: AvatarSize = 'm';

  @Input()
  value: AvatarInput;
}
