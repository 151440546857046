import {Directive, ElementRef, EventEmitter, inject, OnDestroy, Output} from '@angular/core';
import {LoggerService} from '../../logger.service';

@Directive({
  selector: '[appAudio]',
  exportAs: 'appAudio',
})
export class AudioDirective implements OnDestroy {
  private logger = inject(LoggerService).create('Audio', () => ({url: this.src}));

  @Output()
  error = new EventEmitter<ErrorEvent>();

  private readonly playListener = () => {
    this.logger.logInfo('Play');
  };

  private readonly errorListener = error => {
    this.error.emit(error);
    this.logger.logError('Error', {error});
  };

  constructor(private element: ElementRef<HTMLAudioElement>) {
    this.element.nativeElement.addEventListener('play', this.playListener);
    this.element.nativeElement.addEventListener('error', this.errorListener);
  }

  ngOnDestroy() {
    this.element.nativeElement.removeEventListener('play', this.playListener);
    this.element.nativeElement.removeEventListener('error', this.errorListener);
  }

  get src() {
    const element = this.element.nativeElement;
    return element.src || element.currentSrc;
  }

  get played() {
    return this.element.nativeElement.played;
  }

  get volume() {
    return this.element.nativeElement.volume;
  }

  set volume(value: number) {
    this.element.nativeElement.volume = value;
  }

  play() {
    this.logger.logInfo('Play');
    return this.element.nativeElement.play();
  }

  pause() {
    this.logger.logInfo('Pause');
    return this.element.nativeElement.pause();
  }

  stop() {
    this.logger.logInfo('Stop');
    this.element.nativeElement.currentTime = 0;
    return this.element.nativeElement.pause();
  }

  async fadeOut(duration = 1000) {
    await this.changeVolume(0, duration);
    await this.element.nativeElement.pause();
    this.logger.logInfo('Fade out');
  }

  async changeVolume(value: number, duration = 1000, step = 5) {
    if (this.played) {
      let current = this.volume * 100;
      const count = Math.abs(current - value) / step;
      const interval = Math.round(duration / count);
      const direction = current > value;
      await new Promise<void>(resolve => {
        const update = () => {
          if (current === value || (direction && current < value) || (!direction && current > value)) {
            return resolve();
          } else {
            setTimeout(update, interval);
          }

          current = direction ? current - step : current + step;
          try {
            this.volume = current / 100;
          } catch (e) {
            resolve();
          }
        };
        update();
      });
      this.logger.logInfo('Volume', {value});
    }
  }
}
