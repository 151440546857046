import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  @Input() percent: number;

  constructor() {
  }

  get width() {
    return Number(this.percent || 0).toFixed(0) + '%';
  }

  ngOnInit() {
  }
}
