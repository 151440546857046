import {Component, inject} from '@angular/core';
import {LoggerService} from '../../logger.service';

@Component({
  selector: 'app-network-error-dialog',
  templateUrl: './network-error-dialog.component.html',
  styleUrls: ['./network-error-dialog.component.scss']
})
export class NetworkErrorDialogComponent {
  private readonly logger = inject(LoggerService).create('Network error dialog');

  constructor() {
    this.logger.logWarning('Opened');
  }

  reload() {
    window.location.reload();
  }
}
