import {booleanAttribute, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {AnimationItem, AnimationSegment} from 'lottie-web';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {ReplaySubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AnimationOptions} from 'ngx-lottie/lib/symbols';

@Component({
  selector: 'app-animation',
  templateUrl: './animation.component.html',
  styleUrls: ['./animation.component.scss']
})
export class AnimationComponent implements OnDestroy, OnChanges {

  @Input() src: string;
  @Input() width: string;
  @Input() height: string;
  @Input() segments: AnimationSegment[] = [];
  @Input({transform: booleanAttribute}) loop = false;

  @Output() created: EventEmitter<void> = new EventEmitter<void>();
  @Output() loaded: EventEmitter<void> = new EventEmitter<void>();
  @Output() started: EventEmitter<void> = new EventEmitter<void>();
  @Output() completed: EventEmitter<void> = new EventEmitter<void>();

  options: AnimationOptions;

  private instance$: ReplaySubject<AnimationItem>;
  private playing$ = new ReplaySubject<boolean>();

  private subscription: Subscription;

  constructor(private ngZone: NgZone) {
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.src || changes?.segments || changes?.loop) {
      this.instance$ = new ReplaySubject();

      this.options = {
        path: this.src,
        loop: this.loop,
        autoplay: false,
        initialSegment: this.segments[0] || null,
      };

      this.subscription?.unsubscribe();
      this.subscription = this.playing$
        .pipe(
          debounceTime(10),
          distinctUntilChanged(),
        )
        .subscribe(value => value ? this.play() : this.stop());
    }
  }

  @Input()
  set autoplay(value: boolean) {
    if (coerceBooleanProperty(value)) {
      this.playing$.next(true);
    }
  }

  @Input()
  set playing(value: boolean) {
    this.playing$.next(value);
  }

  animationCreated(animationItem: AnimationItem): void {
    this.instance$.next(animationItem);
    this.instance$.complete();
    this.created.emit();
  }

  stop() {
    this.ngZone.runOutsideAngular(async () => {
      const animation = await this.instance$.toPromise();
      animation.stop();
    });
  }

  play() {
    this.ngZone.runOutsideAngular(async () => {
      const animation = await this.instance$.toPromise();
      const segments = this.segments.slice(1);
      if (!!segments.length) {
        animation.playSegments(segments);
      } else {
        animation.play();
      }
      this.started.emit();
    });
  }

  onComplete() {
    this.completed.emit();
  }

  animationLoaded() {
    this.loaded.emit();
  }
}
