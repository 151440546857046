import {Component, OnInit} from '@angular/core';
import {CircleColor, CircleDirection, CircleSize, CircleSpeed} from './background-circle/background-circle.component';

interface Circle {
  size: CircleSize;
  color: CircleColor;
  direction: CircleDirection;
  speed: CircleSpeed;
  right: string;
}

@Component({
  selector: 'app-background-circles',
  templateUrl: './background-circles.component.html',
  styleUrls: ['./background-circles.component.scss']
})
export class BackgroundCirclesComponent implements OnInit {

  counts = [3, 4, 5, 6];
  sizes: CircleSize[] = ['xs', 's', 'm', 'l', 'xl', 'xxl'];
  colors: CircleColor[] = ['light', 'dark'];
  directions: CircleDirection[] = ['top', 'bottom'];
  speeds: CircleSpeed[] = [1, 2, 3, 4, 5];

  circles: Circle[] = [];

  constructor() {
  }

  ngOnInit() {
    this.generate();
  }

  generate() {
    const count = this.counts[Math.floor(Math.random() * this.counts.length)];

    this.circles = [];
    for (let i = 0; i < count; i++) {
      this.circles.push({
        size: getRandomArrayValue(this.sizes),
        color: getRandomArrayValue(this.colors),
        direction: getRandomArrayValue(this.directions),
        speed: getRandomArrayValue(this.speeds),
        right: Math.floor(Math.random() * (80 - 10) + 10) + '%',
      });
    }
  }

}

function getRandomArrayValue<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}
