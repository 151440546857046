import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[uiDuplicate]'
})
export class DuplicateDirective {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }

  @Input('uiDuplicate')
  set duplicate(count: number) {
    this.viewContainer.clear();
    for (let index = 0; index < count; index++) {
      this.viewContainer.createEmbeddedView(this.templateRef, {}, {index});
    }
  }
}
