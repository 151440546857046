import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-button-icon, [app-button-icon]',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent {
  @Input()
  icon: string;

  @Input()
  @HostBinding('attr.color')
  color: 'regular' | 'primary' | 'accent' | 'action' = 'regular';

  @Input()
  @HostBinding('attr.size')
  size: 'xs' | 's' | 'm' | 'l' | 'xl' = 'l';
}
