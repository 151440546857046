import {inject, Injectable} from '@angular/core';
import {LogLevel, LumberjackLogPayload, LumberjackService, LumberjackTimeService} from '@ngworker/lumberjack';

@Injectable({
  providedIn: 'root'
})
export class LoggerService<TPayload extends LumberjackLogPayload | void = void> extends LumberjackService<TPayload> {
  private readonly time = inject(LumberjackTimeService);

  create(scope: string, payload?: TPayload | (() => TPayload)) {
    return new Logger<TPayload>(this, this.time, scope, payload);
  }
}

export class Logger<TPayload extends LumberjackLogPayload | void = void> {

  constructor(private readonly lumberjack: LumberjackService<TPayload>,
              private readonly time: LumberjackTimeService,
              private readonly scope: string,
              private readonly payload?: TPayload | (() => TPayload)) {
  }

  logCritical(message: string, payload?: TPayload) {
    return this.log('critical', message, payload);
  }

  logError(message: string, payload?: TPayload) {
    return this.log('error', message, payload);
  }

  logWarning(message: string, payload?: TPayload) {
    return this.log('warn', message, payload);
  }

  logInfo(message: string, payload?: TPayload) {
    return this.log('info', message, payload);
  }

  logDebug(message: string, payload?: TPayload) {
    return this.log('debug', message, payload);
  }

  logTrace(message: string, payload?: TPayload) {
    return this.log('trace', message, payload);
  }

  private log(level: LogLevel, message: string, payload?: TPayload) {
    const basePayload = typeof this.payload === 'function' ? this.payload() : this.payload;
    this.lumberjack.log({
      scope: this.scope,
      payload: {...basePayload, ...payload},
      message,
      level,
      createdAt: this.time.getUnixEpochTicks(),
    });
  }
}
